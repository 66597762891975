<template>
	<div :class="isAdded ? 'active' : ''" class="qcm-wrapper ai">
		<button v-if="!isAdded" @click="showPopUpEdit" class="edit"><img src="/img/edit.svg" alt=""></button>
	
		<div class="question-container no-cursor" >
			<p class="calcul" ref="question" v-html="clonedQuestion.question">
			</p>
			
		</div>
		
		<div class="answer-container active" :class="isDropDownVisible ? 'active' : ''">
			<p v-for="(answer) in clonedQuestion.answers" :key="answer.id">
				<span :class="answer.is_correct ? 'correct' : ''">
					<input type="checkbox" :checked="answer.is_correct"> <span v-html="answer.answer"></span>  
				</span>
			</p>
		</div>
		<div class="btn-container">
			<button @click="add" class="submit add" :class="isAdded ? 'added' : ''">
				<span v-if="isAdded"> Ajouté</span>
				<span v-else> + Ajouter</span>
			</button>
		</div>
		
		<Teleport to="body">
			<PopUpEditAiGeneratedQCM v-if="isPopUpEditVisible" @close="isPopUpEditVisible = false" :question="clonedQuestion" @editQuestion="editQuestion" />
		</Teleport>
	</div>
	
</template>

<script>
import { createQCMQuestionAnswerService } from '../../../../services/qcmQuestionAnswersServices'
import { createQCMQuestionService } from '../../../../services/qcmQuestionsServices'
import PopUpEditAiGeneratedQCM from '../../../PopUp/QCM/PopUpEditAiGeneratedQCM.vue'

export default {
	components: {  PopUpEditAiGeneratedQCM },
	
	props: {
		question: Object,	
		showImg: {
			type: Boolean,
			default: true
		},
		level: Number,
		chapter: Number,
		subject: Number,
	},
	data(){
		return {
			displayedImg: '',
			isAdded: false,
			isDropDownVisible: false,
			isPopUpEditVisible: false,
			clonedQuestion: {},
		}
	},

	methods:{
		showPopUpEdit(){
			this.isPopUpEditVisible = true
		},
		async add() {
			if (!this.isAdded){
				this.isAdded = !this.isAdded
				const data = new FormData()
				data.append('question', this.clonedQuestion.question)
				data.append('chapter', this.chapter)
				data.append('level', this.level)
				data.append('subject', this.subject)

				const res = await createQCMQuestionService(data)

				for(const answer of this.clonedQuestion.answers){
					const data = {
						answer: answer.answer,
						is_correct: answer.is_correct,
						question: res.id
					}
					await createQCMQuestionAnswerService(data)
				}
				this.$store.state.toast = {
					text: 'A retrouver dans "Mes questions"',
					status: 'success'
				}

			}
		},
		editQuestion(question){
			this.clonedQuestion = question
		},
	},
	watch: {
		added() {
			this.isAdded = this.added
		},	
	},

	created(){
		this.clonedQuestion = JSON.parse(JSON.stringify(this.question))
	}
	
	
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/admin/qcm/qcm-container.scss';
</style>

<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-edit-ai-generated">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<form @submit.prevent="submit">
				<div class="input-container">
					<label for="question">Question</label>
					<LatexEditor :text="clonedQuestion.question" :custom-id="`question`" @update-text="updateQuestion"  />
				</div>
				<div class="input-container">
					<p>Reponses</p>	
					<div class="answer-wrapper" v-for="(answer,index) in clonedQuestion.answers" :key="index">
						<input type="checkbox" v-model="answer.is_correct">
						<LatexEditor :text="answer.answer" :custom-id="`answer${index}`" @update-text="(text) => updateAnswer(text,index)"  />
					</div>
				</div>
					
				<button class="submit dark">Valider</button>
			</form>
		</div>
	</pop-up-wrapper>
</template>

<script>
import LatexEditor from '../../Utils/LatexEditor.vue'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper, LatexEditor },
	emits: ['close'],
	props: {
		question: Object,
	},
	data() {
		return {
			clonedQuestion: {},
		}
	},
	
	methods: {
		updateQuestion(text){
			this.clonedQuestion.question = text
		},
		updateAnswer(text,index){
			this.clonedQuestion.answers[index].answer = text
		},
		submit(){
			this.$emit('editQuestion', this.clonedQuestion)
			this.$emit('close')
		}
	},
	

	created() {
		this.clonedQuestion = JSON.parse(JSON.stringify(this.question))
	},

}
</script>

<style lang="scss">
.pop-up-edit-ai-generated{
	width: 40vw;
	height: fit-content;
	max-height: 90vh;
	overflow: auto;
	position: relative;
	padding: 4rem;

	textarea{
		background-color: #E5EBFF;
		border: none;
		border-radius: 15px;
		padding: 1rem;
		resize: none;
	}

	button{
		margin-inline: auto;
	}

	.input-container{
		margin-bottom: 2rem;
		p{
			font-size: 1.5rem;
			margin-bottom: 1rem;
		}

		.answer-wrapper{
			display: flex;
			align-items: center;
			margin-bottom: 1rem;
			input[type="checkbox"]{
				margin-right: 1rem;
				margin-bottom: 0;
			}
			.input-container{
				flex: 1;
				margin-bottom: 1rem;

			}
			
		}
		.fake-textarea{
			width: 100%;
			height: 100%;
			min-height: 2rem;
			padding: 2rem;
			border: 2px solid #000;
			border-radius: 15px;
		}
	}
}
</style>

<template>
	<section>
		<h1>✨Générer des questions avec I.A</h1>

		<form @submit.prevent="generateQuestion()">
			<div class="all-input-container">
				<div class="input-container">
					<label for="subject">Matière *</label>
					<select name="subject" id="subject" v-model="subject">
						<option v-for="subject in getUserSubjects()" :key="subject.id" :value="subject.id">{{ subject.name }}</option>
					</select>   
				</div>     
				<div class="input-container">
					<label for="level">Niveau *</label>
					<select name="level" id="level" v-model="level">
						<option v-for="level in getUserLevels()" :key="level.id" :value="level.id">{{ level.name }}</option>
					</select>
				</div>	
				<div v-if="showChapter" class="input-container">
					<label for="chapter">Chapitre *</label>
					<select
						class="filter-chapter"
						@change="updateFilter"
						v-model="chapter"
						name="chapter"
						id="chapter"
					>
						<option value="">-----</option>

						<optgroup
							v-for="sequence in getSequence()"
							:key="sequence.id"
							:label="sequence.name"
						>
							<option
								v-for="chapter in this.$store.getters.chapters(
									sequence.id
								)"
								:key="chapter.id"
								:value="chapter.id"
							>
								{{ chapter.name }}
							</option>
						</optgroup>
					</select>
				</div>
				
			</div>
			<div class="input-container">
				<label for="">Information complémentaire</label>
				<textarea rows="6" v-model="info" name="" id=""></textarea>
			</div>
			<button class="submit dark">Générer</button>
		</form>   
		<div class="questions-container qcm">
			<Loader v-if="isLoading" />
			<AIGeneratedQCMQuestion
				v-for="(question, index) in questions"
				:key="index"
				:question="question"
				:chapter="chapter"
				:level="level"
				:subject="subject"
			/>
		
		</div>
	</section>   
</template>
<script>
import { authenticatedFetch, checkRightFor } from '../../../../utils'
import Loader from '../../../Loader.vue'
import AIGeneratedQCMQuestion from './AIGeneratedQCMQuestion.vue'

export default {
	components: {
		Loader,
		AIGeneratedQCMQuestion
	},
	data(){
		return {
			subject: '',
			level: '',
			chapter: '',
			showChapter: true,
			questions: [],
			isQuestionAdded: false,
			isLoading: false,
			info:''

		}
	},

	methods: {
		checkErrors(){
			if (!this.subject || !this.level || !this.chapter){
				this.$store.state.toast = {
					text: 'Veuillez remplir tous les champs',
					status: 'error'
				}
				return true
			}
			return false
		},
		getUserSubjects(){
			const subjects = this.$store.getters.subjects(this.$store.state.user.subjects)
			return subjects
		},
		getUserLevels(){
			const levels = this.$store.getters.levels(this.$store.state.user.levels)
			return levels
		},
		async questionAdded(){
			if (this.isQuestionAdded) return
			this.$store.state.toast = {
				text: 'A retrouver dans "Mes questions"',
				status: 'success'
			}
			this.isQuestionAdded = true
		},
		getSequence(){
			if (this.$store.state.user.subjects.length == 1 ){
				this.subject = this.$store.state.user.subjects[0]
			}
			return this.$store.getters.sequencesByLevelAndSubject(this.level, this.subject)
		},
		async generateQuestion(){
			if(!checkRightFor('QCM',[])){
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			if (this.checkErrors()) return
			this.isLoading = true
			this.questions = []
			const data = {
				subject_name: this.$store.state.subjects.find(s => s.id == this.subject).name,
				chapter_name: this.$store.state.chapters.find(c => c.id == this.chapter).name,
				level_name: this.$store.state.levels.find(l => l.id == this.level).name,
				info: this.info
			}
			const res = await authenticatedFetch('/ai-qcm-generator/', {
				method: 'POST', 
				body: JSON.stringify(data)
			})
				.then(res => res.json())
			this.questions = res

			for(const question of this.questions){
				question.customId = Math.random().toString(36).substring(7)
			}
			this.isLoading = false
		}
	},
	
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/admin/ai-generated-content.scss';
</style>

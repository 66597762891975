<template>

	<div class="input-container">
		<button class="add-latex" @click.prevent="isPopUpLatexVisible = !isPopUpLatexVisible">f(x)</button>

		<div class="fake-textarea" :id="customId" contenteditable></div>

	</div>
	<Teleport to="body">
		<PopUpInsertLatex @close="isPopUpLatexVisible = !isPopUpLatexVisible" @insert-latex="insertLatexText"
			v-if="isPopUpLatexVisible" />
	</Teleport>
</template>

<script>
import PopUpInsertLatex from '../PopUp/SeriousGame/TileGame/PopUpInsertLatex.vue'


export default {
	components: {
		PopUpInsertLatex
	},
	props:{
		customId: String,
		text: {
			type: String,
			default: ''
		}
	},
	emits: ['update-text'],
	data() {
		return {
			isPopUpLatexVisible: false,
			carretPosition: 0,
		}
	},
	methods: {
		updateCarretPosition() {
			return new Promise((resolve) => {
				setTimeout(() => {
					const selection = window.getSelection()
					if (selection.rangeCount > 0) {
						const range = selection.getRangeAt(0)
						this.carretPosition = range.startOffset
					}
					resolve()
				}, 200)
			})
		},
		insertLatexText(latex) {
			this.isPopUpLatexVisible = false
			const position = this.carretPosition
			const mathField = document.createElement('math-field')
			mathField.setAttribute('readonly', '')
			mathField.setAttribute('class', 'mathfield')
			mathField.setAttribute('contenteditable', false)
			mathField.textContent = latex
			mathField.style.cursor = 'pointer'
			const text = document.getElementById(this.customId)
			text.innerHTML = text.innerHTML.replaceAll('&nbsp;', ' ')
			text.innerHTML = text.innerHTML.slice(0, position) + mathField.outerHTML + text.innerHTML.slice(position) + '&nbsp'
			this.isPopUpLatexVisible = false
			this.$emit('update-text', this.removeLastBr(text.innerHTML))
		},	
		removeLastBr(str) {
			const brTag = '<br>';
			const lastIndex = str.lastIndexOf(brTag);

			if (lastIndex !== -1) {
				return str.substring(0, lastIndex) + str.substring(lastIndex + brTag.length);
			}
			
			return str;
		}
	},
	
	mounted() {
		this.$nextTick(() => {
			const text = document.getElementById(this.customId)
			text.innerHTML = this.text
			text.addEventListener('input', async () => {
				await this.updateCarretPosition()
				this.$emit('update-text', this.removeLastBr(text.innerHTML))
			})
			text.addEventListener('click', async () => {
				await this.updateCarretPosition()
			})
		})
	},

}
</script>

<style lang="scss" setup>

.input-container{
	position: relative;
	.add-latex {
		position: absolute;
		top: 0;
		left: 50%;
		translate: -50% -50%;
		background-color: #fefefe;
		border: solid 2px #000;
		border-radius: 15px;
		padding: 0.5rem 1rem;
		cursor: pointer;
	}
}
.fake-textarea {
	width: 100%;
	height: 100%;
	min-height: 150px;
	padding: 2rem;
	background-color: white;
	border: none;
	border-radius: 15px;
	resize: none;

	
}
</style>

import { authenticatedApiFetch } from '../utils'


export const getQCMQuestionAnswerService = async (id) => {
	const res = await authenticatedApiFetch(`/qcm-question-answers/${id}/`, { method: 'GET' })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const filteredQCMQuestionAnswersService = async (filter) => {
	const res = await authenticatedApiFetch(`/qcm-question-answers/?${filter}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const createQCMQuestionAnswerService = async (data) => {
	const res = await authenticatedApiFetch(`/qcm-question-answers/`, { method: 'POST', body: JSON.stringify(data) })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const updateQcmQuestionAnswerService = async (id, data) => {
	const res = await authenticatedApiFetch(`/qcm-question-answers/${id}/`, { method: 'PUT', body: JSON.stringify(data) })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}
